import { rgb, RGB, PDFFont } from "pdf-lib";

/**
 * Draw a texts on Static PDF (Example: FormConsentSpinalTH)
 * @param page - Page to draw
 * @param textCoordinates - Array of texts
 * @param font - Font name
 * @param fontSize - Font size
 * @param textColor - (Optional) Font color
 */
export const drawTextOnPage = (
  page: any,
  textCoordinates: { text: string | undefined; x: number; y: number }[],
  font: any,
  fontSize: number,
  textColor?: RGB,
) => {
  for (const { text, x, y } of textCoordinates) {
    page.drawText(text || "", {
      x,
      y,
      size: fontSize,
      font,
      color: textColor ?? rgb(0, 0, 0),
    });
  }
};

/**
 * Calculate Center of Text (จัดกึ่งกลางให้กับ Text โดยอ้างอิงตาม Position X)
 * @param text - Text
 * @param font - Font Using
 * @param fontSize - Font Size
 * @param x1 - Position of X1
 * @param x2 - Position of X2
 * @param staticWidth - Known Width Case
 * @returns 
 */
export const calculateCenterX = (text: string, font: PDFFont, fontSize: number, x1: number, x2: number, staticWidth?: number) => {
  const textWidth = font.widthOfTextAtSize(text, fontSize);
  const targetWidth = staticWidth ?? (x2 - x1);
  const centerX = x1 + (targetWidth - textWidth) / 2;
  return centerX;
};