import { PDFDocument, StandardFonts } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import { drawTextOnPage, calculateCenterX } from "./StaticPdfUtils";

// PDF Path
const PDF = "/static/pdf/printList/cons_spinal_th.pdf";
const Font = "/static/fonts/THSarabunNew-Bold.ttf"; //* กรณีแก้ Font

const FormConsentSpinalTH = async (props: any) => {
  try {
    const existingPdfBytes = await fetch(PDF).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Loading Custom Font
    const fontUrl = Font;
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);
    const zapFont = await pdfDoc.embedFont(StandardFonts.ZapfDingbats);

    const pages = pdfDoc.getPages();
    const fontSize = 15; //*

    // Common Text
    const commonTextCoordinates = [
      { text: props.items?.patientFullName, x: 379, y: 747 },
      { text: props.items?.hn, x: 380, y: 733 },
      { text: props.items?.patientEncounter, x: 514, y: 733 },
      { text: props.items?.birthdateAd, x: 393, y: 720 },
      { text: props.items?.age.toString(), x: 507, y: 720 },
      { text: props.items?.formatGender, x: 384, y: 707 },
    ];

    // Page 1
    const firstTextCoordinates = [
      { text: props.items?.formatPatient, x: 142, y: 572 },
      { text: props.items?.formatDoctor || "-", x: 248, y: 548 },
    ];

    // Page 3
    const thirdTextCoordinates = [
      { 
        text: props.items?.patientFullName,
        x: calculateCenterX(props.items?.patientFullName, customFont, fontSize, 56, 183),
        y: 575
      },
      { text: props.items?.doctorFullName || "",
        x: calculateCenterX(props.items?.doctorFullName || "", customFont, fontSize, 56, 183),
        y: 506
      },
    ];

    const thirdDateTimeTextCoordinates = [
      { text: props.items?.signedDateAd, x: 74, y: 462 },
      { text: props.items?.printTime, x: 136, y: 462 },
    ];

    // Print date footer
    const printDateTimeTextCoordinates = [
      { text: `${props.items?.signedDateAd} [${props.items?.printTime}]`, x: 104, y: 52 },
    ];

    drawTextOnPage(pages[0], [...commonTextCoordinates, ...firstTextCoordinates], customFont, fontSize);
    drawTextOnPage(pages[1], commonTextCoordinates, customFont, fontSize);
    drawTextOnPage(pages[2], [...commonTextCoordinates, ...thirdTextCoordinates], customFont, fontSize);
    drawTextOnPage(pages[2], thirdDateTimeTextCoordinates, customFont, 10);
    // Footer
    drawTextOnPage(pages[0], printDateTimeTextCoordinates, customFont, 10);
    drawTextOnPage(pages[1], printDateTimeTextCoordinates, customFont, 10);
    drawTextOnPage(pages[2], printDateTimeTextCoordinates, customFont, 10);

    const modifiedPdfBytes = await pdfDoc.save();
    const getAsBase64 = await pdfDoc.saveAsBase64();
    const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
    const modifiedPdfUrl = URL.createObjectURL(blob);

    return { uint8Array: modifiedPdfBytes, blobUrl: modifiedPdfUrl, blobRaw: blob, base64String: getAsBase64 }

  } catch (error) {
    console.error("Error modifying PDF: ", error);
    return {};
  }
};

export default FormConsentSpinalTH;