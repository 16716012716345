import WasmController from 'react-lib/frameworks/WasmController';
import icd10KeyUp from "issara-sdk/apis/icd10KeyUp_core";
import DivisionList from "issara-sdk/apis/DivisionList_core";
import DoctorList from "issara-sdk/apis/DoctorList_core";
import ChoiceView from "issara-sdk/apis/ChoiceView_core"
import ClinicalTermList from "issara-sdk/apis/ClinicalTermList_core";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
import ConstanceView from "issara-sdk/apis/ConstanceView_core";
import MiscellaneousList from "issara-sdk/apis/MiscellaneousList_core";
import MiscellaneousPreviewList from "issara-sdk/apis/MiscellaneousPreviewList_core";
import MiscellaneousOrderList from "issara-sdk/apis/MiscellaneousOrderList_core";
import MiscellaneousOrderDetail from "issara-sdk/apis/MiscellaneousOrderDetail_coreM";
import MiscellaneousOrderListCreate from "issara-sdk/apis/MiscellaneousOrderListCreate_core";
import ChangeZoneView from "issara-sdk/apis/ChangeZoneView_core";
import GetCurrentDoctorView from "issara-sdk/apis/GetCurrentDoctorView_core";
import DoctorOrderDetail from "issara-sdk/apis/DoctorOrderDetail_apps_DPO";
import EpisodeList from "issara-sdk/apis/EpisodeList_core";
import EpisodeEncounterList from "issara-sdk/apis/EpisodeEncounterList_core";
import SpecialtyList from "issara-sdk/apis/SpecialtyList_core";
import NationalityList from "issara-sdk/apis/NationalityList_core";
import EncounterSearchList from "issara-sdk/apis/EncounterSearchList_core";
import DocumentTypeList from "issara-sdk/apis/DocumentTypeList_core";
import DocumentCategoryList from "issara-sdk/apis/DocumentCategoryList_core";
import ScannedDocumentList from "issara-sdk/apis/ScannedDocumentList_core";
import ScannedDocumentSecret from "issara-sdk/apis/ScannedDocumentSecret_core";
import ScannedDocumentDetail from "issara-sdk/apis/ScannedDocumentDetail_core";
import EncounterSearchDetail from "issara-sdk/apis/EncounterSearchDetail_core";
import ReportPrintTrackingResult from "issara-sdk/apis/ReportPrintTrackingResult_core";
import DivisionDetail from "issara-sdk/apis/DivisionDetail_core";
import PrenameList from "issara-sdk/apis/PrenameList_core"
import ReligionList from "issara-sdk/apis/ReligionList_core"
import ProvinceList from "issara-sdk/apis/ProvinceList_core"
import OperatingOrderDetailByEmr from "issara-sdk/apis/OperatingOrderDetailByEmr_apps_ORM";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
import PatientDetailView from "issara-sdk/apis/PatientDetailView_apps_REG";
import DiagnosisMedicalRecordDetail from "issara-sdk/apis/DiagnosisMedicalRecordDetail_apps_DPO";
import TreatmentList from 'issara-sdk/apis/TreatmentList_apps_TRT';
import AdverseReactionList from 'issara-sdk/apis/AdverseReactionList_apps_ADR';
import MaritalStatusList from 'issara-sdk/apis/MaritalStatusList_core';
import AdmitOrderList from 'issara-sdk/apis/AdmitOrderList_apps_ADM';

export type State =
  {
    django?: any,
    eocIsLoading?: boolean,
    eocEncounterList?: any[],
    episodeInfo?: any,
    eocEpisodeSelected?: any,
    eocEnEpData?: any,
    openNote?: any,
    episodeType?: any,
    searchDoctorSpecialties?: any[],
    searchDoctorCurrentPage?: any,
    searchDoctorNumberOfPage?: any,
    searchDoctorList?: any,
    openModScannerError?: boolean,
    openModScanner?: boolean,
    openMorePopup?: boolean,
    items?: any[],
    openCardScannedDocument?: number,
    openPrintList?: number,
    supplyOrderModeOptions?: any[],
    supplyOrderEligibilityOptions?: any[]
  }

export const StateInitial: State =
{
  eocEpisodeSelected: {
    id: -1,
    date_time: "",
  },
  eocIsLoading: false,
  openModScannerError: false,
  openModScanner: false,
  openMorePopup: false,
  items: [],
  supplyOrderModeOptions: [],
  supplyOrderEligibilityOptions: []

}

export type Event =
  { message: "apiToken", params: any }
  |  { message: "getEncounterStatus", params: any }
  |  { message: "getEncounter", params: any }
  |  { message: "putEncounter", params: any }
  |  { message: "getNationalities", params: any }
  |  { message: "getDistrict", params: any }
  |  { message: "getCity", params: any }
  |  { message: "getListPreName", params: any }
  |  { message: "getProvince", params: any }
  |  { message: "getCountry", params: any }
  |  { message: "getReligion", params: any }
  |  { message: "getConstance", params: any }
  |  { message: "getChoices", params: any }
  |  { message: "getChoicesEpisodeType", params: any }
  |  { message: "createEpisode", params: any }
  |  { message: "getEpisode", params: any }
  |  { message: "getEpisodeFromEN", params: any }
  |  { message: "getEpisodeList", params: any }
  |  { message: "changeENToEpisode", params: any }
  |  { message: "cancelENFromEpisode", params: any }
  |  { message: "getENListFromEpisode", params: any }
  |  { message: "getDoctorList", params: any }
  |  { message: "getDoctorNameCodeList", params: any }
  |  { message: "getSpeciality", params: any }
  |  { message: "getProgressionCycleByEmr", params: any }
  |  { message: "getProgressionCycle", params: any }
  |  { message: "getClinicalTermSet", params: any }
  |  { message: "getClinicalTerm", params: any }
  |  { message: "getBarcodePrefix", params: any }
  |  { message: "getDefaultBarcode", params: any }
  |  { message: "getRejectedOrder", params: any }
  |  { message: "getCleaningChoices", params: any }
  |  { message: "getCareer", params: any }
  |  { message: "getICD10ListWithCode", params: any }
  |  { message: "getICD9CMListWithCode", params: any }
  |  { message: "getICD10ListWithTerm", params: any }
  |  { message: "getICD9CMListWithTerm", params: any }
  |  { message: "getICD10ListWithMedTerm", params: any }
  |  { message: "getICD9CMListWithMedTerm", params: any }
  |  { message: "getMiscellaneous", params: any }
  |  { message: "getICD10SublevelListWithCode", params: any }
  |  { message: "getICD9CMSublevelListWithCode", params: any }
  |  { message: "postMiscellaneousOrderPreview", params: any }
  |  { message: "getMiscellaneousOrder", params: any }
  |  { message: "deleteMiscellaneousOrder", params: any }
  |  { message: "patchMiscellaneousOrder", params: any }
  |  { message: "postMiscellaneousOrderCreate", params: any }
  |  { message: "getDivision", params: any }
  |  { message: "getLocation", params: any }
  |  { message: "getDivisionDetail", params: any }
  |  { message: "getDivisionForOPD", params: any }
  |  { message: "getDivisionChoice", params: any }
  |  { message: "getDivisionForOPDChoice", params: any }
  |  { message: "getDoctorChoice", params: any }
  |  { message: "getCurrentDoctor", params: any }
  |  { message: "deleteProgressionCycle", params: any }
  |  { message: "postProgressionCycle", params: any }
  |  { message: "putProgressionCycle", params: any }
  |  { message: "getEncounterSearch", params: any }
  |  { message: "getDocumentType", params: any }
  |  { message: "getDocumentCategory", params: any }
  |  { message: "getDoctor", params: any }
  |  { message: "getScannedDocument", params: any }
  |  { message: "putScannedDocumentUpdateSecret", params: any }
  |  { message: "patchScannedDocument", params: any }
  |  { message: "postScannedDocument", params: any }
  |  { message: "getChoicesCheckoutCause", params: any }
  |  { message: "getZoneOfDivision", params: any }
  |  { message: "changeZone", params: any }
  |  { message: "getEncounterSearchDetail", params: any }
  |  { message: "getTaskTrackingResult", params: any }
  |  { message: "getChoicesBillTransactionTypeIPD", params: any }
  |  { message: "getChoicesAdmitOrderType", params: any }
  |  { message: "getEncounterPatient", params: any }
  |  { message: "getProduct", params: any }
  |  { message: "postMiscellaneousOrderListCreate", params: any }
  |  { message: "postPatientAddress", params: any }
  |  { message: "getPatientAddressList", params: any }
  |  { message: "getPatientAddressDetail", params: any }
  |  { message: "putPatientAddressDetail", params: any }
  |  { message: "deletePatientAddressDetail", params: any }
  |  { message: "getChoicesANSOrderStatus", params: any }
  |  { message: "getDoctorOrderDetail", params: any }
  |  { message: "getEncounterPatientOptimized", params: any }
  |  { message: "getPatientDiagnosisList", params: any }
  |  { message: "getChoicesSupplyOrderMode", params: any}
  |  { message: "getChoicesSupplyOrderEligibility", params: any}
  |  { message: "getOperatingOrderDetailByEmr", params: any}
  |  { message: "getDoctorDetail", params: any}
  |  { message: "getPatientDetailView", params: any}
  |  { message: "getDiagnosisMedicalRecordDetail", params: any}
  |  { message: "getTreatmentDetail", params: any }
  |  { message: "getHospitalNameForPrintList", params: any }
  |  { message: "getTreatmentDetail", params: any }
  |  { message: "getPatientAllergyForPrintList", params: any }
  |  { message: "getPreNameForPrintList", params: any }
  |  { message: "getAdmitOrderForPrintList", params: any }

export type Data =
  {
    division?: number,
  }

export const DataInitial =
{
}

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any

export const apiToken: Handler = async (controller, { }) => {
  //undefined
  //
  //----> Name mismatch between manager and service
}

export const getEncounterStatus: Handler = async (controller, { }) => {
  //function () { return __awaiter(_this, void 0, void 0, function () {
  //            var encounterStatusData, encounterStatusError, coreService, _a, error, response;
  //            return __generator(this, function (_b) {
  //                switch (_b.label) {
  //                    case 0:
  //                        encounterStatusData = [];
  //                        encounterStatusError = null;
  //                        coreService = new CoreService_1.default(this.apiToken);
  //                        return [4 /*yield*/, utils_1.to(coreService.getEncounterStatus())];
  //                    case 1:
  //                        _a = _b.sent(), error = _a[0], response = _a[1];
  //                        if (response) {
  //                            encounterStatusData = response.items;
  //                        }
  //                        else {
  //                            encounterStatusError = error;
  //                        }
  //                        return [2 /*return*/, [encounterStatusData, encounterStatusError]];
  //                }
  //            });
  //        }); }
  //function (_a) {
  //        var _b = _a === void 0 ? {} : _a, params = _b.params;
  //        return this.client
  //            .get(apis_1.CORE.ENCOUNTER_STATUS, { params: params })
  //            .then(this.handleResponse)
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/encounter-status/
}

export const getEncounter: Handler = async (controller, encounterId) => {
  const result = await EncounterDetail.retrieve({
    pk: encounterId,
    apiToken: controller.apiToken
  });
  return [result[0] || [], result[1], result[2]];

  //function (encounterId, apiToken) { return __awaiter(_this, void 0, void 0, function () {
  //            var encounterData, encounterError, coreService, _a, error, response;
  //            return __generator(this, function (_b) {
  //                switch (_b.label) {
  //                    case 0:
  //                        encounterData = [];
  //                        encounterError = null;
  //                        coreService = new CoreService_1.default(this.apiToken);
  //                        return [4 /*yield*/, utils_1.to(coreService.getEncounter(encounterId, apiToken))];
  //                    case 1:
  //                        _a = _b.sent(), error = _a[0], response = _a[1];
  //                        if (response) {
  //                            encounterData = response;
  //                        }
  //                        else {
  //                            encounterError = error;
  //                        }
  //                        return [2 /*return*/, [encounterData, encounterError]];
  //                }
  //            });
  //        }); }
  //function (id, apiToken) {
  //        if (apiToken) {
  //            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
  //        }
  //        var url = apis_1.CORE.ENCOUNTER({ encounter_id: id });
  //        return this.client
  //            .get(url)
  //            .then(this.handleResponse)
  //            .catch(this.throwErrorMessage);
  //    }
  ////function(obj) {
  //obj || (obj = {});
  //var __t, __p = '';
  //with (obj) {
  //__p += 'undefined/apis/core/encounter/' +
  //((__t = (encounter_id)) == null ? '' : __t) +
  //'/';
  //
  //}
  //return __p
  //}
}

export const putEncounter: Handler = async (controller, { }) => {
  //function (_a) {
  //            var _b = _a === void 0 ? {} : _a, type = _b.type, action = _b.action, encounterId = _b.encounterId, patientId = _b.patientId;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var data, params, encounterData, encounterError, coreService, _c, error, response;
  //                return __generator(this, function (_d) {
  //                    switch (_d.label) {
  //                        case 0:
  //                            data = {};
  //                            params = {};
  //                            encounterData = [];
  //                            encounterError = null;
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            if (type) {
  //                                data.type = type;
  //                            }
  //                            if (action) {
  //                                data.action = action;
  //                            }
  //                            if (patientId) {
  //                                data.patient = patientId;
  //                            }
  //                            return [4 /*yield*/, utils_1.to(coreService.putEncounterDetail({ data: data, params: params, encounterId: encounterId }))];
  //                        case 1:
  //                            _c = _d.sent(), error = _c[0], response = _c[1];
  //                            if (response) {
  //                                encounterData = response;
  //                            }
  //                            else {
  //                                encounterError = error;
  //                            }
  //                            return [2 /*return*/, [encounterData, encounterError]];
  //                    }
  //                });
  //            });
  //        }
  //
  //----> Name mismatch between manager and service
}

export const getNationalities: Handler = async (controller, { }) => {
  const result = await NationalityList.list({
    params: { limit: 99999 },
    apiToken: controller.apiToken
  });
  return [result[0]?.items || [], result[1]]
}

export const getDistrict: Handler = async (controller, { }) => {
  //function (_a) {
  //            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, area = _b.area;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var districtData, districtError, coreService, params, _c, error, response;
  //                return __generator(this, function (_d) {
  //                    switch (_d.label) {
  //                        case 0:
  //                            districtData = [];
  //                            districtError = null;
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            params = {};
  //                            if (area) {
  //                                params.area = area;
  //                            }
  //                            return [4 /*yield*/, utils_1.to(coreService.getDistrict({ params: params, apiToken: apiToken }))];
  //                        case 1:
  //                            _c = _d.sent(), error = _c[0], response = _c[1];
  //                            if (response) {
  //                                districtData = response.items;
  //                            }
  //                            else {
  //                                districtError = error;
  //                            }
  //                            return [2 /*return*/, [districtData, districtError]];
  //                    }
  //                });
  //            });
  //        }
  //function (_a) {
  //        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, params = _b.params;
  //        if (apiToken) {
  //            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
  //        }
  //        return this.client
  //            .get(apis_1.CORE.DISTRICT, { params: params })
  //            .then(this.handleResponse)
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/district/
}

export const getCity: Handler = async (controller, { }) => {
  //function (_a) {
  //            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, area = _b.area;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var cityData, cityError, coreService, params, _c, error, response;
  //                return __generator(this, function (_d) {
  //                    switch (_d.label) {
  //                        case 0:
  //                            cityData = [];
  //                            cityError = null;
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            params = {};
  //                            if (area) {
  //                                params.area = area;
  //                            }
  //                            return [4 /*yield*/, utils_1.to(coreService.getCity({ params: params, apiToken: apiToken }))];
  //                        case 1:
  //                            _c = _d.sent(), error = _c[0], response = _c[1];
  //                            if (response) {
  //                                cityData = response.items;
  //                            }
  //                            else {
  //                                cityError = error;
  //                            }
  //                            return [2 /*return*/, [cityData, cityError]];
  //                    }
  //                });
  //            });
  //        }
  //function (_a) {
  //        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, params = _b.params;
  //        if (apiToken) {
  //            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
  //        }
  //        return this.client
  //            .get(apis_1.CORE.CITY, { params: params })
  //            .then(this.handleResponse)
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/city/
}

export const getListPreName: Handler = async (controller, params) => {
  const [r, e, n] = await PrenameList.list({
    apiToken: controller.apiToken,
    params
  }
  );

  return [r?.items || [], e, n]
  //function (_a) {
  //            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, language = _b.language, _c = _b.offset, offset = _c === void 0 ? null : _c, _d = _b.limit, limit = _d === void 0 ? null : _d;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var data, err, coreService, params, _e, error, response;
  //                return __generator(this, function (_f) {
  //                    switch (_f.label) {
  //                        case 0:
  //                            data = [];
  //                            err = null;
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            params = {};
  //                            if (language) {
  //                                params.language = language;
  //                            }
  //                            if (offset) {
  //                                params.offset = offset;
  //                            }
  //                            if (limit) {
  //                                params.limit = limit;
  //                            }
  //                            return [4 /*yield*/, utils_1.to(coreService.getListPreName({ params: params, apiToken: apiToken }))];
  //                        case 1:
  //                            _e = _f.sent(), error = _e[0], response = _e[1];
  //                            if (response) {
  //                                data = response.items;
  //                            }
  //                            else {
  //                                err = error;
  //                            }
  //                            return [2 /*return*/, [data, err]];
  //                    }
  //                });
  //            });
  //        }
  //function (_a) {
  //        var params = _a.params;
  //        return this.client.get(apis_1.CORE.LIST_PRE_NAME, { params: params })
  //            .then(function (response) { return response.data; })
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/prename/
}

export const getProvince: Handler = async (controller, params) => {
  const [r, e, n] = await ProvinceList.list({
    params,
    apiToken: controller.apiToken
  });

  return [r?.items || [], e, n]
  //function (_a) {
  //            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var provinceData, provinceError, coreService, _c, error, response;
  //                return __generator(this, function (_d) {
  //                    switch (_d.label) {
  //                        case 0:
  //                            provinceData = [];
  //                            provinceError = null;
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            return [4 /*yield*/, utils_1.to(coreService.getProvince({ apiToken: apiToken }))];
  //                        case 1:
  //                            _c = _d.sent(), error = _c[0], response = _c[1];
  //                            if (response) {
  //                                provinceData = response.items;
  //                            }
  //                            else {
  //                                provinceError = error;
  //                            }
  //                            return [2 /*return*/, [provinceData, provinceError]];
  //                    }
  //                });
  //            });
  //        }
  //function (_a) {
  //        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken;
  //        if (apiToken) {
  //            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
  //        }
  //        return this.client
  //            .get(apis_1.CORE.PROVINCE)
  //            .then(this.handleResponse)
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/province/
}

export const getCountry: Handler = async (controller, { }) => {
  //function (_a) {
  //            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var countryData, countryError, coreService, _c, error, response;
  //                return __generator(this, function (_d) {
  //                    switch (_d.label) {
  //                        case 0:
  //                            countryData = [];
  //                            countryError = null;
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            return [4 /*yield*/, utils_1.to(coreService.getCountry({ apiToken: apiToken }))];
  //                        case 1:
  //                            _c = _d.sent(), error = _c[0], response = _c[1];
  //                            if (response) {
  //                                countryData = response.items;
  //                            }
  //                            else {
  //                                countryError = error;
  //                            }
  //                            return [2 /*return*/, [countryData, countryError]];
  //                    }
  //                });
  //            });
  //        }
  //function (_a) {
  //        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken;
  //        if (apiToken) {
  //            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
  //        }
  //        return this.client
  //            .get(apis_1.CORE.COUNTRY)
  //            .then(this.handleResponse)
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/country/
}

export const getReligion: Handler = async (controller, params) => {
  const [r, e, n] = await ReligionList.list({
    params,
    apiToken: controller.apiToken
  });

  return [r?.items || [], e, n]
  //function (_a) {
  //            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var religionData, religionError, coreService, _c, error, response;
  //                return __generator(this, function (_d) {
  //                    switch (_d.label) {
  //                        case 0:
  //                            religionData = [];
  //                            religionError = null;
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            return [4 /*yield*/, utils_1.to(coreService.getReligion({ apiToken: apiToken }))];
  //                        case 1:
  //                            _c = _d.sent(), error = _c[0], response = _c[1];
  //                            if (response) {
  //                                religionData = response.items;
  //                            }
  //                            else {
  //                                religionError = error;
  //                            }
  //                            return [2 /*return*/, [religionData, religionError]];
  //                    }
  //                });
  //            });
  //        }
  //function (_a) {
  //        var apiToken = _a.apiToken;
  //        if (apiToken) {
  //            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
  //        }
  //        return this.client
  //            .get(apis_1.CORE.RELIGION)
  //            .then(this.handleResponse)
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/religion/
}

export const getConstance: Handler = async (controller, { list }) => {
  const result = await ConstanceView.get({
    params: { list: list },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division
    }
  });
  return [result[0] || [], result[1]];
}

export const getChoices: Handler = async (controller, { model, field, nameAsId, withEmptyItem }) => {
  let params: any = {};
  if (model) {
    params.model = model;
  }
  if (field) {
    params.field = field;
  }
  if (nameAsId) {
    params.name_as_id = true;
  }
  if (withEmptyItem) {
    params.withEmptyItem = withEmptyItem;
  }
  const result = await ChoiceView.get({
    params: params,
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division
    }
  });
  let identificationDocData = [];
  let identificationDocError = null;
  if (result[0]) {
    const choices = result[0].items.map((item: any) => ({
      id: item.id,
      text: item.label === undefined ? item.value : item.label,
      value: item.value
    }));
    if (withEmptyItem) {
      identificationDocData = [{ id: 0, text: "-", value: "" }, ...choices];
    } else {
      identificationDocData = choices;
    }
  } else {
    identificationDocError = result[1];
  }
  return [identificationDocData, identificationDocError, result[2]];
}

export const getChoicesEpisodeType: Handler = async (controller, { }) => {
  //function () { return __awaiter(_this, void 0, void 0, function () {
  //            var episodeTypeData, episodeTypeError, params, coreService, _a, error, response, choices;
  //            return __generator(this, function (_b) {
  //                switch (_b.label) {
  //                    case 0:
  //                        episodeTypeData = [];
  //                        episodeTypeError = null;
  //                        params = {
  //                            model: "core.Episode",
  //                            field: "episode_type",
  //                            name_as_id: true
  //                        };
  //                        coreService = new CoreService_1.default(this.apiToken);
  //                        return [4 /*yield*/, utils_1.to(coreService.getChoices(params))];
  //                    case 1:
  //                        _a = _b.sent(), error = _a[0], response = _a[1];
  //                        if (error) {
  //                            episodeTypeError = error;
  //                        }
  //                        else {
  //                            if (response) {
  //                                choices = lodashplus_1.default.map(response.items, function (item) { return ({
  //                                    id: item.id,
  //                                    text: item.label === undefined ? item.value : item.label,
  //                                    value: item.value
  //                                }); });
  //                                episodeTypeData = choices;
  //                            }
  //                        }
  //                        return [2 /*return*/, [episodeTypeData, episodeTypeError]];
  //                }
  //            });
  //        }); }
  //
  //----> Name mismatch between manager and service
}

export const createEpisode: Handler = async (controller,
  { patientId, name, params }) => {
  let params_ = {
    patient: patientId,
    episode_type: params?.episodeType,
    name: name,
    description: params?.description
  };
  return await EpisodeList.create({
    data: params_,
    apiToken: controller.apiToken
  });
}

export const getEpisode: Handler = async (controller, { }) => {
  //function (episodeId) { return __awaiter(_this, void 0, void 0, function () {
  //            var episodeData, episodeError, coreService, _a, error, response;
  //            return __generator(this, function (_b) {
  //                switch (_b.label) {
  //                    case 0:
  //                        episodeData = [];
  //                        episodeError = null;
  //                        coreService = new CoreService_1.default(this.apiToken);
  //                        return [4 /*yield*/, utils_1.to(coreService.getEpisode(episodeId))];
  //                    case 1:
  //                        _a = _b.sent(), error = _a[0], response = _a[1];
  //                        if (response) {
  //                            episodeData = response;
  //                        }
  //                        else {
  //                            episodeError = error;
  //                        }
  //                        return [2 /*return*/, [episodeData, episodeError]];
  //                }
  //            });
  //        }); }
  //function (episodeId) {
  //        var url = apis_1.CORE.EPISODE({ episode_id: episodeId });
  //        return this.client
  //            .get(url)
  //            .then(this.handleResponse)
  //            .catch(this.throwErrorMessage);
  //    }
  ////function(obj) {
  //obj || (obj = {});
  //var __t, __p = '';
  //with (obj) {
  //__p += 'undefined/apis/core/episode/' +
  //((__t = (episode_id)) == null ? '' : __t) +
  //'/';
  //
  //}
  //return __p
  //}
}

export const getEpisodeFromEN: Handler = async (controller, { }) => {
  //function (encounterId) { return __awaiter(_this, void 0, void 0, function () {
  //            var episodeData, episodeError, coreService, _a, error, response;
  //            return __generator(this, function (_b) {
  //                switch (_b.label) {
  //                    case 0:
  //                        episodeData = {};
  //                        episodeError = null;
  //                        if (!encounterId) {
  //                            return [2 /*return*/, [episodeData, episodeError]];
  //                        }
  //                        coreService = new CoreService_1.default(this.apiToken);
  //                        return [4 /*yield*/, utils_1.to(coreService.getEpisodeFromEN(encounterId))];
  //                    case 1:
  //                        _a = _b.sent(), error = _a[0], response = _a[1];
  //                        if (response) {
  //                            episodeData = response;
  //                        }
  //                        else {
  //                            episodeError = error;
  //                        }
  //                        return [2 /*return*/, [episodeData, episodeError]];
  //                }
  //            });
  //        }); }
  //function (encounterId) {
  //        var params = { encounter: encounterId };
  //        return this.client
  //            .get(apis_1.CORE.EPISODE_LATEST, { params: params })
  //            .then(this.handleResponse)
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/episode/latest/
}

export const getEpisodeList: Handler = async (controller,
  { patientId = "", createdStartDate = "", createdEndDate = "", doctorNameCode = "", id = "", name = "" }) => {
  const result = await EpisodeList.list({
    params: {
      patient: patientId,
      created_start_date: createdStartDate,
      created_end_date: createdEndDate,
      doctor_name_code: doctorNameCode,
      id: id,
      name: name
    },
    apiToken: controller.apiToken
  });
  return [result[0]?.items || [], result[1]];
}

export const changeENToEpisode: Handler = async (controller, { encounterId, episodeId }) => {
  return await EncounterDetail.update({
    pk: encounterId,
    data: {
      action: "EDIT_EPISODE",
      episode: episodeId,
    }
  });
}

export const cancelENFromEpisode: Handler = async (controller, encounterId) => {
  return await EncounterDetail.update({
    pk: encounterId,
    data: {
      action: "EDIT_EPISODE",
      episode: null,
    }
  });
}

export const getENListFromEpisode: Handler = async (controller, episodeId) => {
  const result = await EpisodeEncounterList.list({
    params: { episode: episodeId },
    apiToken: controller.apiToken
  });
  return [result[0] || [], result[1]];
}

export const getDoctorList: Handler = async (controller, { nameCode, specialty, offset, limit, search }) => {
  let params: any = {};
  if (nameCode) {
    params.name_code = nameCode;
  }
  if (specialty) {
    params.specialty = specialty;
  }
  if (offset) {
    params.offset = offset;
  }
  if (limit) {
    params.limit = limit;
  }
  const result = await DoctorList.list({
    params,
    apiToken: controller.apiToken
  });
  let numberOfPage = null;
  if (result[0]) {
    if (params.limit) {
      numberOfPage = Math.ceil(result[0].total / params.limit);
    }
  }
  return [result[0].items, result[1], numberOfPage]
  //function (_a) {
  //            var _b = _a === void 0 ? {} : _a, nameCode = _b.nameCode, specialty = _b.specialty, offset = _b.offset, limit = _b.limit, search = _b.search;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var params, doctorListData, doctorListError, numberOfPage, coreService, _c, error, response;
  //                return __generator(this, function (_d) {
  //                    switch (_d.label) {
  //                        case 0:
  //                            params = {};
  //                            if (nameCode) {
  //                                params.name_code = nameCode;
  //                            }
  //                            if (specialty) {
  //                                params.specialty = specialty;
  //                            }
  //                            if (offset) {
  //                                params.offset = offset;
  //                            }
  //                            if (limit) {
  //                                params.limit = limit;
  //                            }
  //                            doctorListData = [];
  //                            doctorListError = null;
  //                            numberOfPage = null;
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            return [4 /*yield*/, utils_1.to(coreService.getDoctor(params))];
  //                        case 1:
  //                            _c = _d.sent(), error = _c[0], response = _c[1];
  //                            if (response) {
  //                                doctorListData = response.items;
  //                                if (params.limit) {
  //                                    numberOfPage = Math.ceil(response.total / params.limit);
  //                                }
  //                            }
  //                            else {
  //                                doctorListError = error;
  //                            }
  //                            return [2 /*return*/, [doctorListData, doctorListError, numberOfPage]];
  //                    }
  //                });
  //            });
  //        }
  //
  //----> Name mismatch between manager and service
}

export const getDoctorNameCodeList: Handler = async (controller, { nameCode, limit = 20 }) => {
  let params: any = {};
  if (nameCode) {
    params.name_code = nameCode;
  }
  if (limit) {
    params.limit = limit;
  }
  var doctorListData = [];
  var doctorListError = null;
  var numberOfPage = null;
  const result = await DoctorList.list({
    params: params,
    apiToken: controller.apiToken
  });
  // transform data
  if (result[0]) {
    doctorListData = (result[0]?.items || []).map((item: any) => (
      {
        key: item.id,
        title: item.name_code,
        value: item.name_code
      }
    ))
    if (params.limit) {
      numberOfPage = Math.ceil(result[0].total / params.limit);
    }
  } else {
    doctorListError = result[1];
  }
  return [doctorListData, doctorListError, numberOfPage, result[2]];
}

export const getSpeciality: Handler = async (controller, { }) => {
  const result = await SpecialtyList.list({
    apiToken: controller.apiToken
  });
  return [result[0]?.items || {}, result[1], result[2]];
}

export const getProgressionCycleByEmr: Handler = async (controller, { }) => {
  //function (params) { return __awaiter(_this, void 0, void 0, function () {
  //            var progressionData, progressionError, coreService, _a, error, response;
  //            return __generator(this, function (_b) {
  //                switch (_b.label) {
  //                    case 0:
  //                        progressionData = [];
  //                        progressionError = null;
  //                        coreService = new CoreService_1.default(this.apiToken);
  //                        return [4 /*yield*/, utils_1.to(coreService.getProgressionCycle(params))];
  //                    case 1:
  //                        _a = _b.sent(), error = _a[0], response = _a[1];
  //                        if (response) {
  //                            progressionData = response;
  //                        }
  //                        else {
  //                            progressionError = error;
  //                        }
  //                        return [2 /*return*/, [progressionData, progressionError]];
  //                }
  //            });
  //        }); }
  //
  //----> Name mismatch between manager and service
}

export const getProgressionCycle: Handler = async (controller, { }) => {
  //function (_a) {
  //            var _b = _a === void 0 ? {} : _a, emrId = _b.emrId;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var progressionData, progressionError, params, coreService, _c, error, response;
  //                return __generator(this, function (_d) {
  //                    switch (_d.label) {
  //                        case 0:
  //                            progressionData = [];
  //                            progressionError = null;
  //                            params = {};
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            if (emrId) {
  //                                params.emr = emrId;
  //                            }
  //                            return [4 /*yield*/, utils_1.to(coreService.getProgressionCycle(params))];
  //                        case 1:
  //                            _c = _d.sent(), error = _c[0], response = _c[1];
  //                            if (response) {
  //                                progressionData = response;
  //                            }
  //                            else {
  //                                progressionError = error;
  //                            }
  //                            return [2 /*return*/, [progressionData, progressionError]];
  //                    }
  //                });
  //            });
  //        }
  //function (params) {
  //        return this.client
  //            .get(apis_1.CORE.PROGRESSION_CYCLE, { params: params })
  //            .then(this.handleResponse)
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/progression-cycle/
}

export const getClinicalTermSet: Handler = async (controller, { }) => {
  //function (_a) {
  //            var questionList = _a.questionList;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var data, clinicalTermSet, clinicalTermSetError, coreService, _b, error, response;
  //                return __generator(this, function (_c) {
  //                    switch (_c.label) {
  //                        case 0:
  //                            data = {};
  //                            clinicalTermSet = [];
  //                            clinicalTermSetError = null;
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            if (questionList) {
  //                                data.question = questionList;
  //                            }
  //                            return [4 /*yield*/, utils_1.to(coreService.postClinicalTermSet({ data: data }))];
  //                        case 1:
  //                            _b = _c.sent(), error = _b[0], response = _b[1];
  //                            if (response) {
  //                                clinicalTermSet = response;
  //                            }
  //                            else {
  //                                clinicalTermSetError = error;
  //                            }
  //                            return [2 /*return*/, [clinicalTermSet, clinicalTermSetError]];
  //                    }
  //                });
  //            });
  //        }
  //
  //----> Name mismatch between manager and service
}

export const getClinicalTerm: Handler = async (controller, { type }) => {
  let params: any = {};
  if (type) {
    params.type = type;
  }
  const result = await ClinicalTermList.list({
    params: params,
    apiToken: controller.apiToken
  });
  return [result[0]?.items || [], result[1], result[2]];
}

export const getBarcodePrefix: Handler = async (controller, { }) => {
  //function () { return __awaiter(_this, void 0, void 0, function () {
  //            var barcodePrefixData, barcodePrefixError, coreService, _a, error, response;
  //            return __generator(this, function (_b) {
  //                switch (_b.label) {
  //                    case 0:
  //                        barcodePrefixData = [];
  //                        barcodePrefixError = null;
  //                        coreService = new CoreService_1.default(this.apiToken);
  //                        return [4 /*yield*/, utils_1.to(coreService.getBarcodePrefix())];
  //                    case 1:
  //                        _a = _b.sent(), error = _a[0], response = _a[1];
  //                        if (response) {
  //                            barcodePrefixData = response;
  //                        }
  //                        else {
  //                            barcodePrefixError = error;
  //                        }
  //                        return [2 /*return*/, [barcodePrefixData, barcodePrefixError]];
  //                }
  //            });
  //        }); }
  //function () {
  //        return this.client
  //            .get(apis_1.CORE.BARCODE_PREFIX_LIST)
  //            .then(this.handleResponse)
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/barcode-prefix/
}

export const getDefaultBarcode: Handler = async (controller, { }) => {
  //function (barcode) { return __awaiter(_this, void 0, void 0, function () {
  //            var defaultBarcodeData, defaultBarcodeError, coreService, _a, error, response;
  //            return __generator(this, function (_b) {
  //                switch (_b.label) {
  //                    case 0:
  //                        defaultBarcodeData = [];
  //                        defaultBarcodeError = null;
  //                        if (!barcode) return [3 /*break*/, 2];
  //                        coreService = new CoreService_1.default(this.apiToken);
  //                        return [4 /*yield*/, utils_1.to(coreService.getDefaultBarcode(barcode))];
  //                    case 1:
  //                        _a = _b.sent(), error = _a[0], response = _a[1];
  //                        if (response) {
  //                            defaultBarcodeData = response;
  //                        }
  //                        else {
  //                            defaultBarcodeError = error;
  //                        }
  //                        _b.label = 2;
  //                    case 2: return [2 /*return*/, [defaultBarcodeData, defaultBarcodeError]];
  //                }
  //            });
  //        }); }
  //function (barcode) {
  //        var url = apis_1.CORE.DEFAULT_BARCODE_DETAIL({ barcode: barcode });
  //        return this.client
  //            .get(url)
  //            .then(this.handleResponse)
  //            .catch(this.throwErrorMessage);
  //    }
  ////function(obj) {
  //obj || (obj = {});
  //var __t, __p = '';
  //with (obj) {
  //__p += 'undefined/apis/core/default-barcode/' +
  //((__t = (barcode)) == null ? '' : __t) +
  //'/';
  //
  //}
  //return __p
  //}
}

export const getRejectedOrder: Handler = async (controller, { }) => {
  //function () { return __awaiter(_this, void 0, void 0, function () {
  //            var rejectOrderData, rejectOrderError, coreService, _a, error, response;
  //            return __generator(this, function (_b) {
  //                switch (_b.label) {
  //                    case 0:
  //                        rejectOrderData = [];
  //                        rejectOrderError = null;
  //                        coreService = new CoreService_1.default(this.apiToken);
  //                        return [4 /*yield*/, utils_1.to(coreService.getRejectedOrder())];
  //                    case 1:
  //                        _a = _b.sent(), error = _a[0], response = _a[1];
  //                        if (response) {
  //                            rejectOrderData = response;
  //                        }
  //                        else {
  //                            rejectOrderError = error;
  //                        }
  //                        return [2 /*return*/, [rejectOrderData, rejectOrderError]];
  //                }
  //            });
  //        }); }
  //function () {
  //        return this.client
  //            .get(apis_1.CORE.REJECTED_ORDER)
  //            .then(this.handleResponse)
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/rejected-order/
}

export const getCleaningChoices: Handler = async (controller, { }) => {
  //function () { return __awaiter(_this, void 0, void 0, function () {
  //            var cleaningData, cleaningError, coreService, _a, error, response;
  //            return __generator(this, function (_b) {
  //                switch (_b.label) {
  //                    case 0:
  //                        cleaningData = [];
  //                        cleaningError = null;
  //                        coreService = new CoreService_1.default(this.apiToken);
  //                        return [4 /*yield*/, utils_1.to(coreService.getCleaningChoice())];
  //                    case 1:
  //                        _a = _b.sent(), error = _a[0], response = _a[1];
  //                        if (response) {
  //                            cleaningData = response.items;
  //                        }
  //                        else {
  //                            cleaningError = error;
  //                        }
  //                        return [2 /*return*/, [cleaningData, cleaningError]];
  //                }
  //            });
  //        }); }
  //
  //----> Name mismatch between manager and service
}

export const getCareer: Handler = async (controller, { }) => {
  //function () { return __awaiter(_this, void 0, void 0, function () {
  //            var careerData, careerError, coreService, _a, error, response;
  //            return __generator(this, function (_b) {
  //                switch (_b.label) {
  //                    case 0:
  //                        careerData = [];
  //                        careerError = null;
  //                        coreService = new CoreService_1.default(this.apiToken);
  //                        return [4 /*yield*/, utils_1.to(coreService.getCareer())];
  //                    case 1:
  //                        _a = _b.sent(), error = _a[0], response = _a[1];
  //                        if (response) {
  //                            careerData = response.items;
  //                        }
  //                        else {
  //                            careerError = error;
  //                        }
  //                        return [2 /*return*/, [careerData, careerError]];
  //                }
  //            });
  //        }); }
  //function () {
  //        return this.client
  //            .get(apis_1.CORE.CAREER)
  //            .then(this.handleResponse)
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/career/
}

export const getICD10ListWithCode: Handler = async (controller, icdCode) => {
  const result = await icd10KeyUp.post({
    method: "icdcode",
    data: { icdcode: icdCode },
    apiToken: controller.apiToken
  });
  return [result[0].response || [], result[1], result[2]];
}

export const getICD9CMListWithCode: Handler = async (controller, { }) => {
  //function (icdCode) { return __awaiter(_this, void 0, void 0, function () {
  //            var icd9cmData, icd9cmError, params, coreService, _a, error, response;
  //            return __generator(this, function (_b) {
  //                switch (_b.label) {
  //                    case 0:
  //                        icd9cmData = [];
  //                        icd9cmError = null;
  //                        params = { icdcode: icdCode };
  //                        coreService = new CoreService_1.default(this.apiToken);
  //                        return [4 /*yield*/, utils_1.to(coreService.getICD9CMListWithCode(params))];
  //                    case 1:
  //                        _a = _b.sent(), error = _a[0], response = _a[1];
  //                        if (response) {
  //                            icd9cmData = response;
  //                        }
  //                        else {
  //                            icd9cmError = error;
  //                        }
  //                        return [2 /*return*/, [icd9cmData, icd9cmError]];
  //                }
  //            });
  //        }); }
  //function (params) {
  //        return this.client
  //            .post(apis_1.CORE.ICD9CM_ICDCODE, params)
  //            .then(function (response) { return response.data.response; })
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/icd9cmKeyUp/icdcode
}

export const getICD10ListWithTerm: Handler = async (controller, icdTerm) => {
  const result = await icd10KeyUp.post({
    method: "icdterm",
    data: { icdterm: icdTerm },
    apiToken: controller.apiToken
  });
  return [result[0].response || [], result[1], result[2]];
}

export const getICD9CMListWithTerm: Handler = async (controller, { }) => {
  //function (icdTerm) { return __awaiter(_this, void 0, void 0, function () {
  //            var icd9cmData, icd9cmError, params, coreService, _a, error, response;
  //            return __generator(this, function (_b) {
  //                switch (_b.label) {
  //                    case 0:
  //                        icd9cmData = [];
  //                        icd9cmError = null;
  //                        params = { icdterm: icdTerm };
  //                        coreService = new CoreService_1.default(this.apiToken);
  //                        return [4 /*yield*/, utils_1.to(coreService.getICD9CMListWithTerm(params))];
  //                    case 1:
  //                        _a = _b.sent(), error = _a[0], response = _a[1];
  //                        if (response) {
  //                            icd9cmData = response;
  //                        }
  //                        else {
  //                            icd9cmError = error;
  //                        }
  //                        return [2 /*return*/, [icd9cmData, icd9cmError]];
  //                }
  //            });
  //        }); }
  //function (params) {
  //        return this.client
  //            .post(apis_1.CORE.ICD9CM_ICDTERM, params)
  //            .then(function (response) { return response.data.response; })
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/icd9cmKeyUp/icdterm
}

export const getICD10ListWithMedTerm: Handler = async (controller, medTerm) => {
  const result = await icd10KeyUp.post({
    method: "medterm",
    data: { medterm: medTerm },
    apiToken: controller.apiToken
  });
  return [result[0].response || [], result[1], result[2]];
}

export const getICD9CMListWithMedTerm: Handler = async (controller, { }) => {
  //function (medTerm) { return __awaiter(_this, void 0, void 0, function () {
  //            var icd9cmData, icd9cmError, params, coreService, _a, error, response;
  //            return __generator(this, function (_b) {
  //                switch (_b.label) {
  //                    case 0:
  //                        icd9cmData = [];
  //                        icd9cmError = null;
  //                        params = { medterm: medTerm };
  //                        coreService = new CoreService_1.default(this.apiToken);
  //                        return [4 /*yield*/, utils_1.to(coreService.getICD9CMListWithMedTerm(params))];
  //                    case 1:
  //                        _a = _b.sent(), error = _a[0], response = _a[1];
  //                        if (response) {
  //                            icd9cmData = response;
  //                        }
  //                        else {
  //                            icd9cmError = error;
  //                        }
  //                        return [2 /*return*/, [icd9cmData, icd9cmError]];
  //                }
  //            });
  //        }); }
  //function (params) {
  //        return this.client
  //            .post(apis_1.CORE.ICD9CM_MEDTERM, params)
  //            .then(function (response) { return response.data.response; })
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/icd9cmKeyUp/medterm
}

export const getMiscellaneous: Handler = async (controller,
  { groupCode, name, encounterId, limit, offset }) => {
  let miscellaneousData = [];
  let miscellaneousError = null;
  let params: any = {};
  if (encounterId) {
    params.encounter = encounterId;
  }
  if (name) {
    params.name = name;
  }
  if (groupCode) {
    params.group_code = groupCode;
  }
  if (limit) {
    params.limit = limit;
  }
  if (offset) {
    params.offset = offset;
  }
  const result = await MiscellaneousList.list({
    params: params,
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division
    }
  });
  return [result[0] || [], result[1]];
}

export const getICD10SublevelListWithCode: Handler = async (controller, icdCode) => {
  const result = await icd10KeyUp.post({
    method: "sublevel",
    data: { icdcode: icdCode },
    apiToken: controller.apiToken
  });
  return [result[0].response || [], result[1], result[2]];
}

export const getICD9CMSublevelListWithCode: Handler = async (controller, { }) => {
  //function (icdCode) { return __awaiter(_this, void 0, void 0, function () {
  //            var icd9cmData, icd9cmError, params, coreService, _a, error, response;
  //            return __generator(this, function (_b) {
  //                switch (_b.label) {
  //                    case 0:
  //                        icd9cmData = [];
  //                        icd9cmError = null;
  //                        params = { icdcode: icdCode };
  //                        coreService = new CoreService_1.default(this.apiToken);
  //                        return [4 /*yield*/, utils_1.to(coreService.getICD9CMSublevelListWithCode(params))];
  //                    case 1:
  //                        _a = _b.sent(), error = _a[0], response = _a[1];
  //                        if (response) {
  //                            icd9cmData = response;
  //                        }
  //                        else {
  //                            icd9cmError = error;
  //                        }
  //                        return [2 /*return*/, [icd9cmData, icd9cmError]];
  //                }
  //            });
  //        }); }
  //function (params) {
  //        return this.client
  //            .post(apis_1.CORE.ICD9CM_SUBLEVEL, params)
  //            .then(function (response) { return response.data.response; })
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/icd9cmKeyUp/sublevel
}

export const postMiscellaneousOrderPreview: Handler = async (controller, { params, data }) => {
  const result = await MiscellaneousPreviewList.post({
    params: params,
    data: data,
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division
    }
  });
  return [result[0]?.items || [], result[1]];
}

export const getMiscellaneousOrder: Handler = async (controller,
  { encounterId, active, orderBy, fromDate, toDate, offset, limit }) => {
  var params: any = {};
  if (encounterId) {
    params.encounter_id = encounterId;
  }
  if (active) {
    params.active = active;
  }
  if (orderBy) {
    params.order_by = orderBy;
  }
  if (fromDate) {
    params.from_date = fromDate;
  }
  if (toDate) {
    params.to_date = toDate;
  }
  if (offset) {
    params.offset = offset;
  }
  if (limit) {
    params.limit = limit;
  }
  const result = await MiscellaneousOrderList.list({
    params: params,
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division
    }
  });
  return [result[0] || [], result[1]];
}

export const deleteMiscellaneousOrder: Handler = async (controller, { id, data }) => {
  const result = await MiscellaneousOrderDetail.delete({
    pk: id,
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division
    }
  });
  return [result[0] || [], result[1]]
}

export const patchMiscellaneousOrder: Handler = async (controller, { }) => {
  //function (_a) {
  //            var _b = _a === void 0 ? {} : _a, id = _b.id, apiToken = _b.apiToken, data = _b.data;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var params, coreService, _c, error, response;
  //                return __generator(this, function (_d) {
  //                    switch (_d.label) {
  //                        case 0:
  //                            params = {};
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            return [4 /*yield*/, utils_1.to(coreService.patchMiscellaneousOrder({ id: id, apiToken: apiToken, data: data, params: params }))];
  //                        case 1:
  //                            _c = _d.sent(), error = _c[0], response = _c[1];
  //                            return [2 /*return*/, [response, error]];
  //                    }
  //                });
  //            });
  //        }
  //function (_a) {
  //        var _b = _a === void 0 ? {} : _a, id = _b.id, params = _b.params, data = _b.data, apiToken = _b.apiToken;
  //        if (apiToken) {
  //            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
  //        }
  //        var url = apis_1.CORE.MISCELLANEOUS_ORDER_DETAIL({ miscellaneous_order_id: id });
  //        return this.client
  //            .patch(url, data, { params: params })
  //            .then(this.handleResponse)
  //            .catch(this.throwErrorMessage);
  //    }
  ////function(obj) {
  //obj || (obj = {});
  //var __t, __p = '';
  //with (obj) {
  //__p += 'undefined/apis/core/miscellaneous-order/' +
  //((__t = (miscellaneous_order_id)) == null ? '' : __t) +
  //'/';
  //
  //}
  //return __p
  //}
}

export const postMiscellaneousOrderCreate: Handler = async (controller, { data }) => {
  const result = await MiscellaneousOrderListCreate.post({
    data: data,
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division
    }
  });
  return [result[0] || [], result[1]];
}

// proxy
export const getLocation: Handler = async (controller, params) => {
  return await getDivision(controller, params);
}

export const getDivision: Handler = async (controller, { limit, parent, apiToken, isTelemed, forIpdEncounter, organization }) => {
  let params: any = {};
  if (limit) {
    params.limit = limit;
  }
  if (parent) {
    params.parent = parent;
  }
  if (isTelemed || isTelemed === false) {
    params.is_telemed = isTelemed;
  }
  if (forIpdEncounter) {
    params.for_ipd_encounter = forIpdEncounter;
  }
  if (organization) {
    params.organization = organization;
  }
  const result = await DivisionList.list({
    params: params,
    apiToken: controller.apiToken
  });
  return result;


  //function (_a) {
  //            var _b = _a === void 0 ? {} : _a, limit = _b.limit, parent = _b.parent, apiToken = _b.apiToken, isTelemed = _b.isTelemed, forIpdEncounter = _b.forIpdEncounter;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var params, coreService, _c, error, response;
  //                return __generator(this, function (_d) {
  //                    switch (_d.label) {
  //                        case 0:
  //                            params = {};
  //                            if (limit) {
  //                                params.limit = limit;
  //                            }
  //                            if (parent) {
  //                                params.parent = parent;
  //                            }
  //                            if (isTelemed || isTelemed === false) {
  //                                params.is_telemed = isTelemed;
  //                            }
  //                            if (forIpdEncounter) {
  //                                params.for_ipd_encounter = forIpdEncounter;
  //                            }
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            return [4 /*yield*/, utils_1.to(coreService.getDivision(params, apiToken))];
  //                        case 1:
  //                            _c = _d.sent(), error = _c[0], response = _c[1];
  //                            return [2 /*return*/, [response, error]];
  //                    }
  //                });
  //            });
  //        }
  //function (params, apiToken) {
  //        // console.log(apiToken, "apiToken")
  //        if (apiToken) {
  //            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
  //        }
  //        return this.client
  //            .get(apis_1.CORE.DIVISION, { params: params })
  //            .then(this.handleResponse)
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/division/
}

export const getDivisionDetail: Handler = async (controller, { }) => {
  //function (_a) {
  //            var _b = _a === void 0 ? {} : _a, divisionId = _b.divisionId, apiToken = _b.apiToken;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var params, coreService, _c, error, response;
  //                return __generator(this, function (_d) {
  //                    switch (_d.label) {
  //                        case 0:
  //                            params = {};
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            return [4 /*yield*/, utils_1.to(coreService.getDivisionDetail({ divisionId: divisionId, params: params, apiToken: apiToken }))];
  //                        case 1:
  //                            _c = _d.sent(), error = _c[0], response = _c[1];
  //                            return [2 /*return*/, [response, error]];
  //                    }
  //                });
  //            });
  //        }
  //function (_a) {
  //        var _b = _a === void 0 ? {} : _a, divisionId = _b.divisionId, params = _b.params, apiToken = _b.apiToken;
  //        if (apiToken) {
  //            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
  //        }
  //        return this.client
  //            .get(apis_1.CORE.DIVISION_DETAIL({ divisionId: divisionId }), { params: params })
  //            .then(this.handleResponse)
  //            .catch(this.throwErrorMessage);
  //    }
  ////function(obj) {
  //obj || (obj = {});
  //var __t, __p = '';
  //with (obj) {
  //__p += 'undefined/apis/core/division/' +
  //((__t = (divisionId)) == null ? '' : __t) +
  //'/';
  //
  //}
  //return __p
  //}

  const state = controller.getState();
  const [r, e, n] = await DivisionDetail.retrieve({
    pk: state.django.division,
    apiToken: controller.apiToken
  }
  );
  console.log(" DivisionDetail r: ", r)


}

export const getDivisionForOPD: Handler = async (controller, { }) => {
  return await DivisionList.list({
    params: { for_opd_encounter: true },
    apiToken: controller.apiToken
  });
}

export const getDivisionChoice: Handler = async (controller, { }) => {
  const result = await getDivision(controller, {});
  return [result[0].items
    .map((item: any) =>
      ({ id: item.id, text: item.name, value: item.code })),
  result[1]];
  //function () { return __awaiter(_this, void 0, void 0, function () {
  //            var _a, data, error, choices;
  //            return __generator(this, function (_b) {
  //                switch (_b.label) {
  //                    case 0: return [4 /*yield*/, this.getDivision()];
  //                    case 1:
  //                        _a = _b.sent(), data = _a[0], error = _a[1];
  //                        choices = [];
  //                        if (data.items) {
  //                            choices = lodashplus_1.default.map(data.items, function (item) { return ({
  //                                id: item.id,
  //                                text: item.name,
  //                                value: item.code
  //                            }); });
  //                        }
  //                        return [2 /*return*/, [choices, error]];
  //                }
  //            });
  //        }); }
  //
  //----> Name mismatch between manager and service
}

export const getDivisionForOPDChoice: Handler = async (controller, { }) => {
  const result = await getDivisionForOPD(controller, {});
  let choices = result[0].items ?
    result[0].items.map((item: any) => (
      {
        id: item.id,
        text: item.name_code,
        value: item.code
      }
    ))
    : []
  return [choices, result[1]]
}

export const getDoctorChoice: Handler = async (controller, { }) => {
  const result = await getDoctorList(controller, {});
  console.log(result);
  return [result[0].map((item: any) =>
    ({ id: item.id, text: item.name_code, value: item.code })),
  result[1]]
  //function () { return __awaiter(_this, void 0, void 0, function () {
  //            var _a, data, error, numberOfPage, choices;
  //            return __generator(this, function (_b) {
  //                switch (_b.label) {
  //                    case 0: return [4 /*yield*/, this.getDoctorList()];
  //                    case 1:
  //                        _a = _b.sent(), data = _a[0], error = _a[1], numberOfPage = _a[2];
  //                        choices = [];
  //                        if (data) {
  //                            choices = lodashplus_1.default.map(data, function (item) { return ({
  //                                id: item.id,
  //                                text: item.name_code,
  //                                value: item.code
  //                            }); });
  //                        }
  //                        return [2 /*return*/, [choices, error]];
  //                }
  //            });
  //        }); }
  //
  //----> Name mismatch between manager and service
}

export const getCurrentDoctor: Handler = async (controller, { }) => {
  return await GetCurrentDoctorView.get({
    apiToken: controller.apiToken
  });
}

export const deleteProgressionCycle: Handler = async (controller, { }) => {
  //function (_a) {
  //            var _b = _a === void 0 ? {} : _a, progressionId = _b.progressionId;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var data, errorData, coreService, _c, error, response;
  //                return __generator(this, function (_d) {
  //                    switch (_d.label) {
  //                        case 0:
  //                            data = [];
  //                            errorData = null;
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            return [4 /*yield*/, utils_1.to(coreService.deleteProgressionCycle({ progressionId: progressionId }))];
  //                        case 1:
  //                            _c = _d.sent(), error = _c[0], response = _c[1];
  //                            if (response) {
  //                                data = response;
  //                            }
  //                            else {
  //                                errorData = error;
  //                            }
  //                            return [2 /*return*/, [data, errorData]];
  //                    }
  //                });
  //            });
  //        }
  //function (_a) {
  //        var _b = _a === void 0 ? {} : _a, data = _b.data, params = _b.params, progressionId = _b.progressionId;
  //        var url = apis_1.CORE.PROGRESSION_CYCLE_DETAIL({
  //            progression_cycle_id: progressionId
  //        });
  //        return this.client
  //            .delete(url, data)
  //            // .delete(url, data, { params })
  //            .then(this.handleResponse)
  //            .catch(this.throwErrorMessage);
  //    }
  ////function(obj) {
  //obj || (obj = {});
  //var __t, __p = '';
  //with (obj) {
  //__p += 'undefined/apis/core/progression-cycle/' +
  //((__t = (progression_cycle_id)) == null ? '' : __t) +
  //'/';
  //
  //}
  //return __p
  //}
}

export const postProgressionCycle: Handler = async (controller, { }) => {
  //function (_a) {
  //            var _b = _a === void 0 ? {} : _a, emrId = _b.emrId;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var progressionData, errorData, coreService, data, _c, error, response;
  //                return __generator(this, function (_d) {
  //                    switch (_d.label) {
  //                        case 0:
  //                            progressionData = [];
  //                            errorData = null;
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            data = {};
  //                            if (emrId) {
  //                                data.emr = emrId;
  //                            }
  //                            return [4 /*yield*/, utils_1.to(coreService.postProgressionCycle({ data: data }))];
  //                        case 1:
  //                            _c = _d.sent(), error = _c[0], response = _c[1];
  //                            if (response) {
  //                                progressionData = response;
  //                            }
  //                            else {
  //                                errorData = error;
  //                            }
  //                            return [2 /*return*/, [progressionData, errorData]];
  //                    }
  //                });
  //            });
  //        }
  //function (_a) {
  //        var _b = _a === void 0 ? {} : _a, data = _b.data, params = _b.params, progressionId = _b.progressionId;
  //        return this.client
  //            .post(apis_1.CORE.PROGRESSION_CYCLE, data, { params: params })
  //            .then(this.handleResponse)
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/progression-cycle/
}

export const putProgressionCycle: Handler = async (controller, { }) => {
  //function (_a) {
  //            var _b = _a === void 0 ? {} : _a, emrId = _b.emrId, progressionNote = _b.progressionNote, progressionId = _b.progressionId;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var progressionData, errorData, coreService, data, _c, error, response;
  //                return __generator(this, function (_d) {
  //                    switch (_d.label) {
  //                        case 0:
  //                            progressionData = [];
  //                            errorData = null;
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            data = {};
  //                            if (emrId) {
  //                                data.emr = emrId;
  //                            }
  //                            if (progressionNote) {
  //                                data.progression_note = progressionNote;
  //                            }
  //                            return [4 /*yield*/, utils_1.to(coreService.putProgressionCycle({ data: data, progressionId: progressionId }))];
  //                        case 1:
  //                            _c = _d.sent(), error = _c[0], response = _c[1];
  //                            if (response) {
  //                                progressionData = response;
  //                            }
  //                            else {
  //                                errorData = error;
  //                            }
  //                            return [2 /*return*/, [progressionData, errorData]];
  //                    }
  //                });
  //            });
  //        }
  //function (_a) {
  //        var _b = _a === void 0 ? {} : _a, data = _b.data, params = _b.params, progressionId = _b.progressionId;
  //        var url = apis_1.CORE.PROGRESSION_CYCLE_DETAIL({
  //            progression_cycle_id: progressionId
  //        });
  //        return this.client
  //            .put(url, data, { params: params })
  //            .then(this.handleResponse)
  //            .catch(this.throwErrorMessage);
  //    }
  ////function(obj) {
  //obj || (obj = {});
  //var __t, __p = '';
  //with (obj) {
  //__p += 'undefined/apis/core/progression-cycle/' +
  //((__t = (progression_cycle_id)) == null ? '' : __t) +
  //'/';
  //
  //}
  //return __p
  //}
}

export const getEncounterSearch: Handler = async (controller,
  { search, encounterId, patientId, patientHn, limit, offset }
) => {
  let params: any = {};
  if (search) {
    params.search = search;
  }
  if (encounterId) {
    params.id = encounterId;
  }
  if (patientId) {
    params.patient = patientId;
  }
  if (patientHn) {
    params.patient__hn = patientHn;
  }
  if (limit) {
    params.limit = limit;
  }
  if (offset) {
    params.offset = offset;
  }
  return await EncounterSearchList.list({
    params: params,
    apiToken: controller.apiToken
  });
}

export const getDocumentType: Handler = async (controller,
  { limit, category, name, division, printable, offset, code }
) => {
  let params: any = {};
  if (limit) {
    params.limit = limit;
  }
  if (offset) {
    params.offset = offset;
  }
  if (category) {
    params.category = category;
  }
  if (name) {
    params.name = name;
  }
  if (division) {
    params.division = division;
  }
  if (printable) {
    params.printable = printable;
  }
  if (code) {
    params.code = code;
  }
  return await DocumentTypeList.list({
    params: params,
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division
    }
  });
}

export const getDocumentCategory: Handler = async (controller, { limit }) => {
  let params: any = { limit: 99999 };
  if (limit) {
    params.limit = limit;
  }
  return await DocumentCategoryList.list({
    params: params,
    apiToken: controller.apiToken
  });
}

export const getDoctor: Handler = async (controller, { search, name_code }) => {
  let params: any = {};
  if (search) {
    params.search = search;
  };
  if (name_code) {
    params.name_code = name_code;
  }
  return await DoctorList.list({
    params: params,
    apiToken: controller.apiToken
  });
}

export const getScannedDocument: Handler = async (controller,
  { patientHn, encounterId, documentType, documentNo, scanDivision, scanUser, ownerDivision,
    encounterDoctor, docFromDate, docToDate, scanFromDate, scanToDate, expiryFromDate, expiryToDate, offset, limit }
) => {
  let params: any = {};
  if (patientHn) {
    params.patient__hn = patientHn;
  }
  if (encounterId) {
    params.encounter = encounterId;
  }
  if (documentType) {
    params.document_type = documentType;
  }
  if (documentNo) {
    params.document_no = documentNo;
  }
  if (scanDivision) {
    params.scan_division = scanDivision;
  }
  if (scanUser) {
    params.scan_user = scanUser;
  }
  if (ownerDivision) {
    params.owner_division = ownerDivision;
  }
  if (encounterDoctor) {
    params.encounter__doctor = encounterDoctor;
  }
  if (docFromDate) {
    params.doc_date_from = docFromDate;
  }
  if (docToDate) {
    params.doc_date_to = docToDate;
  }
  if (scanFromDate) {
    params.scan_date_from = scanFromDate;
  }
  if (scanToDate) {
    params.scan_date_to = scanToDate;
  }
  if (expiryFromDate) {
    params.expiry_date_from = expiryFromDate;
  }
  if (expiryToDate) {
    params.expiry_date_to = expiryToDate;
  }
  if (offset) {
    params.offset = offset;
  }
  if (limit) {
    params.limit = limit;
  }
  return await ScannedDocumentList.list({
    params: params,
    apiToken: controller.apiToken
  });
}

export const putScannedDocumentUpdateSecret: Handler = async (controller,
  { username, password, items }
) => {
  let data: any = {};
  let params = {};
  if (username) {
    data.username = username;
  }
  if (password) {
    data.password = password;
  }
  if (items) {
    data.items = items;
  }
  return await ScannedDocumentSecret.put({
    params: params,
    data: data,
    apiToken: controller.apiToken
  });
}

export const patchScannedDocument: Handler = async (controller,
  { username, password, cancelNote, scanDocId, active, documentType,
    documentNo, version, ownerDivision, documentDate, expiryDate, remark }
) => {
  let data: any = {};
  let params: any = {};
  if (username) {
    data.username = username;
  }
  if (password) {
    data.password = password;
  }
  if (cancelNote) {
    data.cancel_note = cancelNote;
  }
  if (active || active === false) {
    data.active = active;
  }
  if (documentType) {
    data.document_type = documentType;
  }
  if (documentNo) {
    data.document_no = documentNo;
  }
  if (version) {
    data.version = version;
  }
  if (ownerDivision) {
    data.owner_division = ownerDivision;
  }
  if (documentDate) {
    data.document_date = documentDate;
  }
  if (expiryDate) {
    data.expiry_date = expiryDate;
  }
  if (remark) {
    data.remark = remark;
  }
  return await ScannedDocumentDetail.patch({
    apiToken: controller.apiToken,
    pk: scanDocId,
    params: params,
    data: data
  });
}

export const postScannedDocument: Handler = async (controller, { data }) => {
  let saveData: any = {};
  let params = {};
  if (data) {
    saveData = data;
  }
  return await ScannedDocumentList.create({
    params: params,
    data: data,
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division
    }
  });
}

export const getChoicesCheckoutCause: Handler = async (controller, { }) => {
  return await getChoices(controller,
    {
      model: "core.MedicalRecord",
      field: "checkout_cause",
      nameAsId: false
    });
}

export const getZoneOfDivision: Handler = async (controller, divisionId) => {
  let params: any = {
    type: "ZONE",
    parent: divisionId
  };
  const result = await DivisionList.list({
    params: params,
    apiToken: controller.apiToken
  });
  return [result[0]?.items || [], result[1]]
}

export const changeZone: Handler = async (controller, { encounterId, zoneId }) => {
  let data: any = { encounter: encounterId };
  if (zoneId) {
    data.zone = zoneId;
  }
  return await ChangeZoneView.put({
    encounter_id: encounterId,
    data: data
  });
}

export const getEncounterSearchDetail: Handler = async (controller, { encounterId }) => {
  return await EncounterSearchDetail.retrieve({
    pk: encounterId,
    apiToken: controller.apiToken
  });
}

export const getTaskTrackingResult: Handler = async (controller, { taskId, action }) => {
  let params: any = {};
  if (taskId) {
    params.task_id = taskId;
  }
  if (action) {
    params.action = action;
  }
  return await ReportPrintTrackingResult.retrieve({
    params: params,
    apiToken: controller.apiToken
  });
}

export const getChoicesBillTransactionTypeIPD: Handler = async (controller, { }) => {
  //function () { return __awaiter(_this, void 0, void 0, function () {
  //            var params;
  //            return __generator(this, function (_a) {
  //                switch (_a.label) {
  //                    case 0:
  //                        params = {
  //                            model: "INF.BillTransactionIPD",
  //                            field: "submit_type",
  //                            nameAsId: false
  //                        };
  //                        return [4 /*yield*/, this.getChoices(params)];
  //                    case 1: return [2 /*return*/, _a.sent()];
  //                }
  //            });
  //        }); }
  //
  //----> Name mismatch between manager and service
}

export const getChoicesAdmitOrderType: Handler = async (controller, { }) => {
  //function () { return __awaiter(_this, void 0, void 0, function () {
  //            var params;
  //            return __generator(this, function (_a) {
  //                switch (_a.label) {
  //                    case 0:
  //                        params = {
  //                            model: "ADM.AdmitOrder",
  //                            field: "admit_type",
  //                            nameAsId: false
  //                        };
  //                        return [4 /*yield*/, this.getChoices(params)];
  //                    case 1: return [2 /*return*/, _a.sent()];
  //                }
  //            });
  //        }); }
  //
  //----> Name mismatch between manager and service
}

export const getEncounterPatient: Handler = async (controller, { }) => {
  //function (_a) {
  //            var fromDate = _a.fromDate, toDate = _a.toDate, hn = _a.hn, name = _a.name, excludeCanceled = _a.excludeCanceled;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var params, coreService, _b, error, response, network;
  //                return __generator(this, function (_c) {
  //                    switch (_c.label) {
  //                        case 0:
  //                            params = {};
  //                            if (excludeCanceled) {
  //                                params.exclude_canceled = excludeCanceled;
  //                            }
  //                            if (fromDate) {
  //                                params.from_date = fromDate;
  //                            }
  //                            if (toDate) {
  //                                params.to_date = toDate;
  //                            }
  //                            if (hn) {
  //                                params.patient_hn = hn;
  //                            }
  //                            if (name) {
  //                                params.patient_name = name;
  //                            }
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            return [4 /*yield*/, utils_1.to(coreService.getEncounterPatient({ params: params }))];
  //                        case 1:
  //                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
  //                            return [2 /*return*/, [response, error, network]];
  //                    }
  //                });
  //            });
  //        }
  //function (_a) {
  //        var _b = _a === void 0 ? {} : _a, params = _b.params;
  //        return this.client
  //            .get(apis_1.CORE.ENCOUNTER_PATIENT, { params: params })
  //            .then(function (response) { return response.data; })
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/encounter-patient/
}

export const getProduct: Handler = async (controller, { }) => {
  //function (_a) {
  //            var apiToken = _a.apiToken, name = _a.name, code = _a.code;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var params, coreService, _b, error, response, network;
  //                return __generator(this, function (_c) {
  //                    switch (_c.label) {
  //                        case 0:
  //                            params = {};
  //                            if (name) {
  //                                params.name__icontains = name;
  //                            }
  //                            if (code) {
  //                                params.code__icontains = code;
  //                            }
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            return [4 /*yield*/, utils_1.to(coreService.getProduct({ params: params, apiToken: apiToken }))];
  //                        case 1:
  //                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
  //                            return [2 /*return*/, [response, error, network]];
  //                    }
  //                });
  //            });
  //        }
  //function (_a) {
  //        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken;
  //        if (apiToken) {
  //            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
  //        }
  //        return this.client.get(apis_1.CORE.PRODUCT, { params: params })
  //            .then(function (response) { return response.data; })
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/product/
}

export const postMiscellaneousOrderListCreate: Handler = async (controller, { }) => {
  //function (_a) {
  //            var apiToken = _a.apiToken, orderList = _a.orderList, division = _a.division;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var params, data, coreService, _b, error, response, network;
  //                return __generator(this, function (_c) {
  //                    switch (_c.label) {
  //                        case 0:
  //                            params = {};
  //                            data = {};
  //                            if (orderList) {
  //                                data.miscellaneous_orders = orderList;
  //                            }
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            return [4 /*yield*/, utils_1.to(coreService.postMiscellaneousOrderListCreate({ params: params, apiToken: apiToken, data: data, division: division }))];
  //                        case 1:
  //                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
  //                            return [2 /*return*/, [response, error, network]];
  //                    }
  //                });
  //            });
  //        }
  //function (_a) {
  //        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken, data = _b.data, division = _b.division;
  //        if (apiToken) {
  //            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
  //        }
  //        if (division) {
  //            this.client.defaults.headers["division"] = division;
  //        }
  //        return this.client.post(apis_1.CORE.MISCELLANEOUS_ORDER_LIST_CREATE, data, { params: params })
  //            .then(function (response) { return response.data; })
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/miscellaneous-order-list-create/
}

export const postPatientAddress: Handler = async (controller, { }) => {
  //function (_a) {
  //            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, data = _b.data;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var params, coreService, _c, error, response, network;
  //                return __generator(this, function (_d) {
  //                    switch (_d.label) {
  //                        case 0:
  //                            params = {};
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            return [4 /*yield*/, utils_1.to(coreService.postPatientAddress({ params: params, apiToken: apiToken, data: data }))];
  //                        case 1:
  //                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
  //                            return [2 /*return*/, [response, error, network]];
  //                    }
  //                });
  //            });
  //        }
  //function (_a) {
  //        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken, data = _b.data;
  //        if (apiToken) {
  //            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
  //        }
  //        return this.client.post(apis_1.CORE.PATIENT_ADDRESS, data, { params: params })
  //            .then(function (response) { return response.data; })
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/patient-address/
}

export const getPatientAddressList: Handler = async (controller, { }) => {
  //function (_a) {
  //            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, patientId = _b.patientId, defaultAddress = _b.defaultAddress;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var params, coreService, _c, error, response, network;
  //                return __generator(this, function (_d) {
  //                    switch (_d.label) {
  //                        case 0:
  //                            params = {};
  //                            if (patientId) {
  //                                params.patient = patientId;
  //                            }
  //                            if (defaultAddress) {
  //                                params.default = defaultAddress;
  //                            }
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            return [4 /*yield*/, utils_1.to(coreService.getPatientAddressList({ params: params, apiToken: apiToken }))];
  //                        case 1:
  //                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
  //                            return [2 /*return*/, [response, error, network]];
  //                    }
  //                });
  //            });
  //        }
  //function (_a) {
  //        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken;
  //        if (apiToken) {
  //            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
  //        }
  //        return this.client.get(apis_1.CORE.PATIENT_ADDRESS, { params: params })
  //            .then(function (response) { return response.data; })
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/patient-address/
}

export const getPatientAddressDetail: Handler = async (controller, { }) => {
  //function (_a) {
  //            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, patientAddressId = _b.patientAddressId;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var params, coreService, _c, error, response, network;
  //                return __generator(this, function (_d) {
  //                    switch (_d.label) {
  //                        case 0:
  //                            params = {};
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            return [4 /*yield*/, utils_1.to(coreService.getPatientAddressDetail({ params: params, apiToken: apiToken, patientAddressId: patientAddressId }))];
  //                        case 1:
  //                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
  //                            return [2 /*return*/, [response, error, network]];
  //                    }
  //                });
  //            });
  //        }
  //function (_a) {
  //        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken, patientAddressId = _b.patientAddressId;
  //        if (apiToken) {
  //            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
  //        }
  //        return this.client.get(apis_1.CORE.PATIENT_ADDRESS_DETAIL({ patientAddressId: patientAddressId }), { params: params })
  //            .then(function (response) { return response.data; })
  //            .catch(this.throwErrorMessage);
  //    }
  ////function(obj) {
  //obj || (obj = {});
  //var __t, __p = '';
  //with (obj) {
  //__p += 'undefined/apis/core/patient-address/' +
  //((__t = (patientAddressId)) == null ? '' : __t) +
  //'/';
  //
  //}
  //return __p
  //}
}

export const putPatientAddressDetail: Handler = async (controller, { }) => {
  //function (_a) {
  //            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, data = _b.data, patientAddressId = _b.patientAddressId;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var params, coreService, _c, error, response, network;
  //                return __generator(this, function (_d) {
  //                    switch (_d.label) {
  //                        case 0:
  //                            params = {};
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            return [4 /*yield*/, utils_1.to(coreService.putPatientAddressDetail({ params: params, apiToken: apiToken, data: data, patientAddressId: patientAddressId }))];
  //                        case 1:
  //                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
  //                            return [2 /*return*/, [response, error, network]];
  //                    }
  //                });
  //            });
  //        }
  //function (_a) {
  //        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken, patientAddressId = _b.patientAddressId, data = _b.data;
  //        if (apiToken) {
  //            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
  //        }
  //        return this.client.put(apis_1.CORE.PATIENT_ADDRESS_DETAIL({ patientAddressId: patientAddressId }), data, { params: params })
  //            .then(function (response) { return response.data; })
  //            .catch(this.throwErrorMessage);
  //    }
  ////function(obj) {
  //obj || (obj = {});
  //var __t, __p = '';
  //with (obj) {
  //__p += 'undefined/apis/core/patient-address/' +
  //((__t = (patientAddressId)) == null ? '' : __t) +
  //'/';
  //
  //}
  //return __p
  //}
}

export const deletePatientAddressDetail: Handler = async (controller, { }) => {
  //function (_a) {
  //            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, data = _b.data, patientAddressId = _b.patientAddressId;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var params, coreService, _c, error, response, network;
  //                return __generator(this, function (_d) {
  //                    switch (_d.label) {
  //                        case 0:
  //                            params = {};
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            return [4 /*yield*/, utils_1.to(coreService.deletePatientAddressDetail({ params: params, apiToken: apiToken, patientAddressId: patientAddressId }))];
  //                        case 1:
  //                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
  //                            return [2 /*return*/, [response, error, network]];
  //                    }
  //                });
  //            });
  //        }
  //function (_a) {
  //        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken, patientAddressId = _b.patientAddressId;
  //        if (apiToken) {
  //            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
  //        }
  //        return this.client.delete(apis_1.CORE.PATIENT_ADDRESS_DETAIL({ patientAddressId: patientAddressId }), { params: params })
  //            .then(function (response) { return response.data; })
  //            .catch(this.throwErrorMessage);
  //    }
  ////function(obj) {
  //obj || (obj = {});
  //var __t, __p = '';
  //with (obj) {
  //__p += 'undefined/apis/core/patient-address/' +
  //((__t = (patientAddressId)) == null ? '' : __t) +
  //'/';
  //
  //}
  //return __p
  //}
}

export const getChoicesANSOrderStatus: Handler = async (controller, { }) => {
  //function () { return __awaiter(_this, void 0, void 0, function () {
  //            var params;
  //            return __generator(this, function (_a) {
  //                switch (_a.label) {
  //                    case 0:
  //                        params = {
  //                            model: "ANS.AnesthesiaOrder",
  //                            field: "status",
  //                            nameAsId: true
  //                        };
  //                        return [4 /*yield*/, this.getChoices(params)];
  //                    case 1: return [2 /*return*/, _a.sent()];
  //                }
  //            });
  //        }); }
  //
  //----> Name mismatch between manager and service
}

export const getDoctorOrderDetail: Handler = async (controller, { id }) => {
  let params: any = {};
  return await DoctorOrderDetail.retrieve({
    pk: id,
    apiToken: controller.apiToken
  });
}

export const getEncounterPatientOptimized: Handler = async (controller, { }) => {
  //function (_a) {
  //            var params = _a.params;
  //            return __awaiter(_this, void 0, void 0, function () {
  //                var coreService, _b, error, response, network;
  //                return __generator(this, function (_c) {
  //                    switch (_c.label) {
  //                        case 0:
  //                            coreService = new CoreService_1.default(this.apiToken);
  //                            return [4 /*yield*/, utils_1.to(coreService.getEncounterPatientOptimized({ params: params }))];
  //                        case 1:
  //                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
  //                            return [2 /*return*/, [response, error, network]];
  //                    }
  //                });
  //            });
  //        }
  //function (_a) {
  //        var _b = _a === void 0 ? {} : _a, params = _b.params;
  //        return this.client
  //            .get(apis_1.CORE.ENCOUNTER_PATUENT_OPTIMIZED, { params: params })
  //            .then(function (response) { return response.data; })
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/encounter-patient-optimized/
}

export const getPatientDiagnosisList: Handler = async (controller, { }) => {
  //function (params) { return __awaiter(_this, void 0, void 0, function () {
  //            var coreService, _a, error, response, network;
  //            return __generator(this, function (_b) {
  //                switch (_b.label) {
  //                    case 0:
  //                        coreService = new CoreService_1.default(this.apiToken);
  //                        return [4 /*yield*/, utils_1.to(coreService.getPatientDiagnosisList(params))];
  //                    case 1:
  //                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
  //                        return [2 /*return*/, [response, error, network]];
  //                }
  //            });
  //        }); }
  //function (params) {
  //        return this.client
  //            .get(apis_1.CORE.PATIENT_DIAGNOSIS_LIST, { params: params })
  //            .then(this.handleResponse)
  //            .catch(this.throwErrorMessage);
  //    }
  //undefined/apis/core/patient-diagnosis-list/
}

export const getChoicesSupplyOrderMode: Handler = async (controller, params) => {
  const [res, err, network] = await getChoices(controller,
    {
      model: "MSD.Supply",
      field: "mode",
    });
  if (err) {
    controller.setState({
      supplyOrderModeOptions: [],
    });
  } else {
    console.log("res", res)
    if (res.length > 0) {
      let prepare = res.map((item: any) => ({ key: item.id, value: item.text, text: item.value }))
      prepare.unshift({ key: 0, value: "ANY", text: "ANY" })
      controller.setState({
        supplyOrderModeOptions: prepare,
      });
    }

  }
}


export const getChoicesSupplyOrderEligibility: Handler = async (controller, params) => {
  const [res, err, network] = await getChoices(controller,
    {
      model: "MSD.SupplyOrderItem",
      field: "eligibility_type",
    });
  if (err) {
    controller.setState({
      supplyOrderEligibilityOptions: [],
    });
  } else {
    console.log("res", res)
    if (res.length > 0) {
      let prepare = res.map((item: any) => ({ key: item.id, value: item.value, text: item.text }))
      controller.setState({
        supplyOrderEligibilityOptions: prepare,
      });
    }
  }
}

export const getOperatingOrderDetailByEmr: Handler = async (controller,
  { emrId }
) => {
  return await OperatingOrderDetailByEmr.retrieve({
    pk: emrId,
    apiToken: controller.apiToken
  });
}

export const getDoctorDetail: Handler = async (controller, { doctorId }) => {
  return await DoctorDetail.retrieve({
    pk: doctorId,
    apiToken: controller.apiToken
  });
}

export const getPatientDetailView: Handler = async (controller, { patientId }) => {
  return await PatientDetailView.retrieve({
    pk: patientId,
    apiToken: controller.apiToken
  });
}

export const getDiagnosisMedicalRecordDetail: Handler = async (controller, { emrId }) => {
  return await DiagnosisMedicalRecordDetail.retrieve({
    pk: emrId,
    apiToken: controller.apiToken
  });
}

export const getTreatmentDetail: Handler = async (controller, { code }) => {
  return await TreatmentList.list({
    apiToken: controller.apiToken,
    params: {
      code: code
    }
  });
}

export const getHospitalNameForPrintList: Handler = async (controller, { language }) => {
  return await ConstanceView.get({
    params: { list: language === "EN" ? "core_HOSPITAL_NAME_EN" : "core_HOSPITAL_NAME" },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division
    }
  });
}

export const getPatientAllergyForPrintList: Handler = async (controller, { patientId }) => {
  return await AdverseReactionList.list({
    params: {
      patient: patientId,
      exclude_cancel: true,
      // today: true,
      limit: 999
    },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division
    }
  });
}

export const getPreNameForPrintList: Handler = async (controller, { language }) => {
  return await PrenameList.list({
    params: {
      language: language,
    },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    }
  });
}

export const getAdmitOrderForPrintList: Handler = async (controller, { encounterId }) => {
  return await AdmitOrderList.list({
    params: {
      encounter: encounterId,
      status: 5,
    },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    }
  });
}